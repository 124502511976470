<template>
  <transition name="modalappear">
    <div style="z-index: 100" class="fixed">
      <!-- Background -->
      <div
        @mousedown.self="closeSearch"
        class="screen-overlay fixed inset-0 bg-grey1 bg-opacity-25 flex z-20 lg:pl-12 flex justify-center"
      >
        <!-- Modal window -->
        <div
          class="modal fixed bg-white dark:bg-base5 lg:rounded-xl z-20 shadow flex flex-col"
        >
          <!-- content -->
          <div class="flex flex-col">
            <!-- HEAD -->
            <div class="flex py-3 px-6">
              <div class="flex flex-grow text-3xl">
                <div class="flex items-center justify-center search-icon">
                  <div v-if="isSearching" class="loader"></div>
                  <div v-else class="mt-0.5 lg:mt-px">
                    <label for="search-input">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        class="stroke-current text-grey3"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="3"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </label>
                  </div>
                </div>
                <m-input
                  ref="searchInput"
                  class="text-xl lg:text-3xl text-grey1 font-normal lg:ml-1 pl-3 placeholder-grey4"
                  id="search-input"
                  value=""
                  placeholder="Search all of your notes..."
                  v-model="searchTerm"
                  @input="onChange"
                  title="Search"
                  autocomplete="off"
                />
              </div>

              <div class="flex items-center justify-center search-icon">
                <div
                  v-if="searchTerm != ''"
                  @click="clearSearchInput"
                  class="cursor-pointer"
                >
                  <svg
                    class="stroke-current text-base2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    stroke-width="1.85"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </div>
              </div>
            </div>
            <!-- CONTENT -->
            <div v-if="hits" class="">
              <div class="bg-base2 bg-opacity-50" style="height: 2px"></div>
              <template v-if="hits.hits">
                <div class="modal-content flex flex-col overflow-y-auto">
                  <div
                    v-for="(item, index) in hits.hits"
                    :key="item._id"
                    class="flex flex-col"
                  >
                    <router-link
                      :to="getRouterParams(item._id)"
                      @click.native.exact="closeSearch()"
                    >
                      <search-item
                        :item="item"
                        :index="index"
                        :highlight="!isMobileOnly && index == indexSelected"
                        :isPrivate="getRouteInfo(item._id).private"
                        @mouseenter.native="onSearchItemHover(index)"
                      />
                    </router-link>
                  </div>
                  <div
                    v-if="!loadMoreNoResult && nextFrom < hits.total.value"
                    class="flex mt-4 mb-4 justify-center"
                  >
                    <m-button
                      button-style="btn-tertiary"
                      :pending="isLoadingMore"
                      icon="📋"
                      class="mr-2"
                      @click="loadMore"
                    >
                      Load more
                    </m-button>
                  </div>
                </div>
                <div class="bg-grey6" style="height: 2px"></div>
              </template>
              <!-- FOOTER -->
              <div class="flex flex-col justify-center footer">
                <div class="flex lg:justify-between px-6 text-grey3 font-base">
                  <div class="font-sm" v-if="hits.total">
                    <span class="font-bold"> {{ hits.total.value }}</span>
                    note<span v-if="hits.total.value != 1">s</span>
                    <template v-if="accountPlan <= 1">
                      - unlock
                      <a-link
                        nolinkstyle
                        class="underline cursor-pointer hover:opacity-75"
                        href="https://help.meetric.app/en/articles/5507341-solo-premium"
                        segmentName="Premium intent: solo, search"
                      >
                        more history
                      </a-link>
                    </template>
                  </div>
                  <div class="hidden lg:block">
                    <span v-if="hits.total.value > 0"
                      >ENTER to select,&nbsp;</span
                    >
                    ESC to close
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// 2 weeks future
// 3 monts back

import MInput from '@/components/UI/MInput';
import MButton from '@/components/UI/MButton';
import ALink from '@/components/UI/ALink';
import { debounce } from 'debounce';
import { search } from '@/api/Cherry';
import SearchItem from './SearchItem.vue';

export default {
  name: 'Search',
  components: {
    MInput,
    ALink,
    MButton,
    SearchItem,
  },
  props: {
    showed: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: '',
      lastResultTimestamp: 0,
      debounced: null,
      isSearching: false,
      isLoadingMore: false,
      hits: null,
      searchResultIds: null,
      indexSelected: -1,
      scrollDisableFn: null,
      searchTracked: false,
      loadMoreNoResult: false,
      nextFrom: 0,
    };
  },
  watch: {
    showed: {
      handler(val) {
        if (val) {
          window.addEventListener('keydown', this.onKeydown);
          // document.body.classList.add('no-scroll');
          this.focusInput();
        } else {
          window.removeEventListener('keydown', this.onKeydown);
          // document.body.classList.remove('no-scroll');
        }
      },
    },
  },

  methods: {
    onSearchItemHover(index) {
      if (this.isMobileOnly) return;

      this.indexSelected = index;
    },
    focusInput() {
      this.$refs.searchInput.$el.focus();
    },

    onKeydown(e) {
      switch (e.key) {
        case 'Escape':
          this.closeSearch();
          break;
        case 'Enter':
          if (this.indexSelected < 0 || this.isMobileOnly) break; // Enter before result showed do nothing
          e.preventDefault();
          this.selectItem(this.indexSelected);
          break;
        case 'ArrowUp':
        case 'ArrowDown':
          e.preventDefault();
          this.onArrowUpDown(e.key);
          break;
        default:
          break;
      }
    },
    onArrowUpDown(key) {
      if (!this.hits?.hits) return;

      if (key == 'ArrowUp') {
        this.indexSelected =
          (this.indexSelected + this.hits.hits.length - 1) %
          this.hits.hits.length;
      } else if (key == 'ArrowDown') {
        this.indexSelected = (this.indexSelected + 1) % this.hits.hits.length;
      }

      return true;
    },
    closeSearch() {
      this.$emit('close');
      // this.clearSearchInput();
    },
    selectItem(index) {
      const item = this.hits.hits[index];

      this.$router.push(this.getRouterParams(item._id));
      this.closeSearch();
    },
    getRouterParams(id) {
      const routeInfo = this.getRouteInfo(id);
      return {
        name: 'meeting',
        params: { id: routeInfo.id, calendarId: '' },
        query: { private: routeInfo.private ? null : undefined },
      };
    },
    getRouteInfo(id) {
      const parts = id.split(' ');

      if (parts.length == 1) {
        return { id: parts[0], private: false };
      } else if (parts.length == 2) {
        return { id: parts[1], private: true };
      }

      return { id: '', private: false };
    },
    clearSearchInput() {
      this.searchTerm = '';
      this.onChange('');
      this.focusInput();
    },

    onChange(term) {
      this.debounced?.clear();

      if (term == '') {
        this.hits = null;
        this.lastResultTimestamp = Date.now();
        this.isSearching = false;
        this.indexSelected = -1;
        this.searchTracked = false;
        this.loadMoreNoResult = false;
        this.nextFrom = 0;
        return;
      }

      this.debounced = debounce(this.search(term), 250);
      this.debounced();

      if (!this.searchTracked) {
        window.meetric.track('Search used', {});
        this.searchTracked = true;
      }
    },
    search(term) {
      return () => {
        const start = Date.now();
        this.isSearching = true;

        search({ query: term }).then((r) => {
          if (!r?.hits) {
            this.isSearching = false;
            return;
          }

          if (this.lastResultTimestamp < start) {
            const hits = r.hits.hits || [];
            this.lastResultTimestamp = start;
            this.hits = r.hits;
            this.nextFrom = hits.length;
            this.isSearching = false;

            if (!this.isMobileOnly && this.hits?.total?.value > 0)
              this.indexSelected = 0;
            else this.indexSelected = -1;

            this.searchResultIds = new Set(hits.map((h) => h._id));
          }
        });
      };
    },
    loadMore() {
      this.isLoadingMore = true;

      search({ query: this.searchTerm, from: this.nextFrom }).then((r) => {
        if (!r?.hits) {
          this.isLoadingMore = false;
          return;
        }

        r.hits?.hits?.forEach((h) => {
          if (this.searchResultIds.has(h._id)) return;

          this.hits?.hits?.push(h);
          this.searchResultIds.add(h._id);
        });

        this.nextFrom += r.hits?.hits?.length || 0;
        this.isLoadingMore = false;
        if (!r.hits?.hits) this.loadMoreNoResult = true;
      });
    },
  },
  computed: {
    accountPlan() {
      return this.$store.getters['plan'];
    },
    isMobileOnly() {
      return (
        this.$store?.getters['isMobile'] && !this.$store?.getters['isExtension']
      );
    },
  },
};
</script>

<style scoped>
.footer {
  @apply text-sm;
  height: 3.15rem;
}
.modal {
  @apply w-full;
  top: 3rem;
  max-height: calc(100vh - 7rem);
}
.modal-content {
  /* modal max-heigh - height of header and footer(2*50.4) - 2*2px separator*/
  height: calc(100vh - 7rem - 105px);
}

.search-icon {
  width: 1.5rem;
}
.search-icon svg {
  width: 1.3rem;
  height: 1.3rem;
}
.loader {
  width: 1.2rem;
  height: 1.2rem;
}

@screen lg {
  .footer {
    @apply text-base;
    height: 3.75rem;
  }
  .modal {
    @apply w-3/5;
    top: 8.5rem;
    max-height: calc(100vh - 13rem);
  }
  .modal-content {
    /* modal max-heigh - height of header  and footer(2*60px) - 2*2px separator */
    height: calc(100vh - 13rem - 124px);
  }
  .search-icon {
    width: 1.8rem;
  }
  .search-icon svg {
    width: 1.6rem;
    height: 1.6rem;
  }
  .loader {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.loader {
  border-radius: 50%;
  position: relative;
  border-top: 0.2rem solid rgba(0, 164, 189, 0.2);
  border-right: 0.2rem solid rgba(0, 164, 189, 0.2);
  border-bottom: 0.2rem solid rgba(0, 164, 189, 0.2);
  border-left: 0.2rem solid rgba(0, 164, 189, 1);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.modal-shadow {
  box-shadow: 0rem 0.5rem 1rem 0.1rem rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0rem 0.5rem 1rem 0.1rem rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0rem 0.5rem 1rem 0.1rem rgba(0, 0, 0, 0.03);
}

/* Transition */
.modalappear-enter-active {
  transition: opacity 0.4s ease;
}
.modalappear-leave-active {
  transition: opacity 0.2s ease;
}
.modalappear-enter,
.modalappear-leave-to {
  opacity: 0;
}
.dark .screen-overlay {
  background-color: rgba(20, 20, 20, 0.65);
}
</style>
