import { sendRequestBase, getParamsBase, tokenType } from './Common';

// match backend doc access
export const ACCESS_LEVEL = {
  NONE: 1,
  READ: 2,
  ACTION_UPDATE: 4,
  WRITE: 8,
};
export const ACCESS_TYPE = {
  NONE: 0,
  PUBLIC: 1,
  EMAIL: 2,
  TOKEN: 3,
  PASSWORD: 4,
  ACCOUNT_LIMIT: 5,
};

// check access for meeting
export async function checkMeetingAccess(calendarId, meetingId) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/useraccess`;
  const params = await getParams(null, 'GET', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}

/* Meeting */
export async function getMeetricRelatedMeetings(
  calendarId,
  meetingId,
  searchParams = {}
) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/related`;
  const params = await getParams(null, 'GET', tokenType.ACCESS);

  return sendRequest(endpoint, params, searchParams);
}

export async function getCalendarEventFromMeetric(calendarId, meetingId) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}`;

  const params = await getParams(null, 'GET', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}

export async function addGuests(calendarId, meetingId, guests) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/participants`;
  const params = await getParams(guests, 'POST', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}

export async function getAccess(calendarId, meetingId) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/access`;
  const params = await getParams(null, 'GET', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}

export async function uploadImage(opts) {
  let endpoint = `/meetings/${opts.mid}/images`;
  const params = await getParams({ file: opts.file }, 'POST');
  return sendRequest(endpoint, params);
}
export async function deleteImage(opts) {
  let endpoint = `/meetings/${opts.mid}/images/${opts.uuid}`;
  const params = await getParams(null, 'DELETE');
  return sendRequest(endpoint, params);
}

export async function createMeeting() {
  let endpoint = '/meetings/new';
  const params = await getParams(null, 'POST');
  return sendRequest(endpoint, params);
}
export async function patchMeetingTitle(calendarId, meetingId, fields) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}`;
  const params = await getParams(fields, 'PATCH', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}

export async function getOverview(calendarId, meetingId) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/overview`;
  const params = await getParams(null, 'GET');
  return sendRequest(endpoint, params);
}
export async function getOverviewMulti(meetingIds) {
  let endpoint = '/meetings/overview';
  const params = await getParams(meetingIds, 'POST');
  return sendRequest(endpoint, params);
}
export async function insertSampleNotes(meetingId) {
  let endpoint = `/calendars/primary/meetings/${meetingId}/createsample`;
  const params = await getParams(null, 'POST', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}

// TODO make more efficient (i.e. don't send eventInfo on every single change)
export async function setPublic(
  calendarId,
  meetingId,
  publicAccess,
  writeAccess,
  eventInfo = {}
) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/access`;
  const sharingOptions = {
    public_read: publicAccess,
    public_write: publicAccess && writeAccess, // set to write value only if shared at all
    event_info: eventInfo,
  };
  const params = await getParams(sharingOptions, 'PUT', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}

// TODO make more efficient (i.e. don't send eventInfo on every single change)
export async function setPeopleAccess(
  calendarId,
  meetingId,
  enabled,
  people,
  eventInfo = {}
) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/access`;
  const sharingOptions = {
    email_access: enabled,
    additional_guests: people,
    event_info: eventInfo,
  };
  // console.log(sharingOptions);
  const params = await getParams(sharingOptions, 'PUT');
  return sendRequest(endpoint, params);
}

/* Meeting extras */
export async function getAgendaTemplates() {
  let endpoint = '/extra/templates';
  const params = await getParams(null, 'GET');
  return sendRequest(endpoint, params);
}
export async function getIcebreakers() {
  let endpoint = '/extra/icebreakers';
  const params = await getParams(null, 'GET');
  return sendRequest(endpoint, params);
}
export async function getCustomSnippets() {
  let endpoint = '/extra/snippets';
  const params = await getParams(null, 'GET');
  return sendRequest(endpoint, params);
}
export async function setCustomSnippet(payload) {
  let endpoint = '/extra/snippet';
  const params = await getParams(payload, 'PUT');
  return sendRequest(endpoint, params);
}
export async function deleteCustomSnippet(snippetId) {
  let endpoint = '/extra/snippet/' + snippetId;
  const params = await getParams(null, 'DELETE');
  return sendRequest(endpoint, params);
}

/* Actions */
export async function getUserNotesWithActions() {
  let endpoint = '/actions/notes';
  const params = await getParams(null, 'GET');
  return sendRequest(endpoint, params);
}

export async function getMyActionCount() {
  let endpoint = '/actions/total';
  const params = await getParams(null, 'GET');
  return sendRequest(endpoint, params);
}

/* Users */
export async function setProfile(user) {
  let endpoint = '/user';
  const params = await getParams(user, 'POST');
  return sendRequest(endpoint, params);
}

export async function getCompanyUsers() {
  let endpoint = '/user/company';
  const params = await getParams(null, 'GET');
  return sendRequest(endpoint, params);
}

export async function getMeetricProfile(email) {
  let endpoint = '/users/' + email;
  const params = await getParams(null, 'GET');
  return sendRequest(endpoint, params);
}

export async function getPreferences() {
  let endpoint = '/user/preferences';
  const params = await getParams(null, 'GET');

  return sendRequest(endpoint, params);
}

export async function setPreferences(pref) {
  let endpoint = '/user/preferences';
  const params = await getParams(pref, 'POST');
  return sendRequest(endpoint, params);
}

export async function setLogout() {
  let endpoint = '/user/logout';
  const params = await getParams(null, 'POST');
  return sendRequest(endpoint, params);
}

export async function addCalendar(cal) {
  let endpoint = '/user/calendars';
  const params = await getParams(cal, 'POST');
  return sendRequest(endpoint, params);
}

export async function removeCalendar(calId) {
  let endpoint = '/user/calendars/' + encodeURIComponent(calId);
  const params = await getParams(null, 'DELETE');
  return sendRequest(endpoint, params);
}

export async function grantOfflineAccess(code) {
  let endpoint = '/offlineaccess';
  const params = await getParams({ code }, 'POST');
  return sendRequest(endpoint, params);
}

/* Reminders */
export async function setReminder(payload, calendarId, meetingId, actionId) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/remind/${actionId}`;
  const params = await getParams(payload, 'POST', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}

export async function deleteReminder(calendarId, meetingId, actionId) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/remind/${actionId}`;
  const params = await getParams(null, 'DELETE', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}
export async function setMeetingReminder(payload, calendarId, meetingId) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/meetingreminder`;
  const params = await getParams(payload, 'POST', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}

export async function getMeetingReminder(calendarId, meetingId, previousMeetingId) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/meetingreminder?prev=${previousMeetingId}`;
  const params = await getParams(null, 'GET', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}
export async function getMeetingAutoCancel(calendarId, meetingId) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/autocancel`;
  const params = await getParams(null, 'GET', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}

/* Emails */
export async function sendSummary(payload, calendarId, meetingId) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/emailsummary`;
  const params = await getParams(payload, 'POST', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}

export async function previewSummary(payload, calendarId, meetingId) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/emailpreview`;
  const params = await getParams(payload, 'POST', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}

/* Search */
export async function search(payload) {
  const endpoint = '/search';
  const params = await getParams(payload, 'POST');
  return sendRequest(endpoint, params);
}

/* Integrations */
export async function connectNotion(code) {
  const endpoint = '/integrations/notion';
  const params = await getParams(code, 'POST');
  return sendRequest(endpoint, params);
}

export async function removeNotionIntegration() {
  const endpoint = '/integrations/notion';
  const params = await getParams(null, 'DELETE');
  return sendRequest(endpoint, params);
}

export async function saveNotionDatabaseId(payload) {
  const endpoint = '/integrations/notion/databaseid';
  const params = await getParams(payload, 'POST');
  return sendRequest(endpoint, params);
}

export async function listNotionPages() {
  const endpoint = '/integrations/notion/pages';
  const params = await getParams(null, 'GET');
  return sendRequest(endpoint, params);
}

export async function connectSlackIntegration(code) {
  const endpoint = '/integrations/slack';
  const params = await getParams(code, 'POST');
  return sendRequest(endpoint, params);
}
export async function removeSlackIntegration() {
  const endpoint = '/integrations/slack';
  const params = await getParams(null, 'DELETE');
  return sendRequest(endpoint, params);
}
export async function listChannelsSlackIntegration() {
  const endpoint = '/integrations/slack/channels';
  const params = await getParams(null, 'GET');
  return sendRequest(endpoint, params);
}
export async function setDefaultChannelSlackIntegration(payload) {
  const endpoint = '/integrations/slack/channel/default';
  const params = await getParams(payload, 'POST');
  return sendRequest(endpoint, params);
}
export async function sendMessageSlackIntegration(payload) {
  const endpoint = '/integrations/slack/message';
  const params = await getParams(payload, 'POST');
  return sendRequest(endpoint, params);
}

export async function exportToNotion(payload, calendarId, meetingId) {
  let endpoint = `/calendars/${calendarId}/meetings/${meetingId}/export/notion`;
  const params = await getParams(payload, 'POST', tokenType.ACCESS);
  return sendRequest(endpoint, params);
}

/* Subscription */
export async function subscribe(members = [], isTeam = false) {
  let endpoint = `/subscribe?team=${isTeam}`;
  const params = await getParams(members, 'POST');
  return sendRequest(endpoint, params);
}

export async function unsubscribe(now = false, cancel = false) {
  let endpoint = `/unsubscribe?now=${now}&cancel=${cancel}`;
  const params = await getParams(null, 'POST');
  return sendRequest(endpoint, params);
}

/* Team */
export async function getTeam() {
  let endpoint = `/user/team`;
  const params = await getParams(null, 'GET');
  return sendRequest(endpoint, params);
}

/* Referral */
export async function referral() {
  let endpoint = `/user/referral`;
  const params = await getParams(null, 'GET');
  return sendRequest(endpoint, params);
}

export async function referralEmail(mailList, code) {
  let endpoint = `/user/referral/${code}`;
  const params = await getParams(mailList, 'POST');
  return sendRequest(endpoint, params);
}


async function getParams(body, verb, tokenTypeToAdd = tokenType.ID) {
  return getParamsBase(body, verb, tokenTypeToAdd);
}

async function sendRequest(endpoint, params, searchQuery = {}) {
  const apiURL = process.env.VUE_APP_AWS_API_URL;
  const urlParams = new URLSearchParams(window.location.search);
  const p = urlParams.get('p');
  const newParams = new URLSearchParams({ ...searchQuery });
  if (p) newParams.append('p', p); // append password only if exists
  const url = `${apiURL}${endpoint}${newParams.toString() ? `?${newParams.toString()}` : ''
    }`;

  return sendRequestBase(url, params);
}
