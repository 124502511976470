import store from '@/store';
import router from '@/router';
import { setProfile, getCompanyUsers } from '@/api/Cherry';
import { getCalendarEventById } from '@/api/Google';
import preferences from './preferences';

// make a html link from text
export function linkify(text) {
  // eslint-disable-next-line
  var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, function (url) {
    return '<a target="_blank" href="' + url + '">' + url + '</a>';
  });
}

// returns totals, completed actions and notes
export function getCountFromDoc(jsonDoc) {
  let total = 0;
  let completed = 0;
  let notes = 0;

  jsonDoc.content?.forEach((element) => {
    if (element.type == 'action_item') {
      total++;
      if (element.attrs.completed) completed++;
    } else if (element.type === 'text' || element.type === 'image') {
      notes++;
    } else if (
      element.type === 'listItem' &&
      element.content?.length === 1 &&
      !element.content[0].content
    ) {
      //empty list - no content but list exists
      notes++;
    } else {
      const childrens = getCountFromDoc(element);
      total += childrens.total;
      completed += childrens.completed;
      notes += childrens.notes;
    }
  });

  return { completed, total, notes };
}

// return dateTime or date from event
export function getDatetimeFromEvent(event) {
  // console.log('getDatetimeFromMeeting');
  if (event.start.dateTime) {
    return event.start.dateTime;
  } else if (event.start.date) {
    return event.start.date;
  }
  return '';
}

// return recurringInfo if any
export function getRecurringInfoFromEvent(event) {
  let info = { id: '', end: '' };
  if (event.recurringEventId) {
    info.id = event.recurringEventId;

    if (event.end.dateTime) {
      info.end = event.end.dateTime;
    } else if (event.end.date) {
      // RFC3339 format, add time to all day events
      info.end = event.end.date + 'T00:00:00+11';
    }
  }

  return info;
}

// return T/F if the event is in past
export function isPastEvent(event) {
  // Using start date for all-day events
  // prevents multi-day events from keeping
  // currentEvent/futureEvent focus
  const endTime = event.end.dateTime
    ? new Date(event.end.dateTime)
    : new Date(event.start.date);
  const now = new Date();

  if (endTime >= now) return false;

  return true;
}

// returns T/F if the meeting is happening now
export function isCurrentEvent(event) {
  if (!isPastEvent(event) && event.end.dateTime) {
    let start = new Date(getDatetimeFromEvent(event));
    const now = new Date();
    if (start < now) {
      return true;
    }
  }
  return false;
}

/**
 * Returns Meetric profile for given email or null if not found
 *
 * @param {string} Email you want to get a profile for
 * @return {Promise} Promise with profile or null
 */
export async function getProfile(email) {
  if (!email) return null;

  let profile = store.getters.profile(email);

  if (profile && !profile.loading) return profile.profile;
  else if (profile && profile.loading) {
    const timer = (ms) => new Promise((res) => setTimeout(res, ms));
    await timer(2000);
    profile = store.getters.profile(email);
    if (profile && !profile.loading) return profile.profile;
  }

  //no profile cached or fetching
  await store.dispatch('fetchProfile', email);
  profile = store.getters.profile(email);
  return profile?.profile; // ? profile : null
}

/**
 * Returns name for given email or email if not found
 *
 * @param {string} Email you want to get a name for
 * @return {Promise} Promise with name or email
 */
export async function getName(email) {
  const profile = await getProfile(email);
  if (profile?.name && profile.name != '') return profile.name;
  return email;
}

/**
 * Returns user object used for collab
 *
 * @return {Object} User object
 */
export function generateCollabUser(gAuth = null) {
  const color = generateCursorColor();
  if (!gAuth?.isAuthorized) return { name: 'anonymous', email: '', color };

  const name = gAuth.basicProfile?.getName();
  const email = gAuth.basicProfile?.getEmail();

  return { name: name || 'anonymous', email: email || '', color };
}

function generateCursorColor() {
  const bright = '96'; // Keep one of RGB nice and bright (150 == hex 96)
  const str = (Math.random() * 0xffffff).toString(16).slice(-6); // len=6
  const i = Math.floor(Math.random() * 3) * 2; // 0,2,4
  return '#' + str.substring(0, i) + bright + str.substring(i + 2);
}

// get array of meeting attendees, including organiser if not as attendee
export function getMeetingAttendees(googleEvent) {
  if (isEmpty(googleEvent)) return [];

  const guestList = [...(googleEvent.attendees || [])];

  // include organizer if it is not already there
  const emails = guestList.map((a) => a.email);

  if (
    googleEvent?.organizer?.email &&
    !emails.includes(googleEvent.organizer.email)
  ) {
    guestList.push(googleEvent.organizer);
    emails.push(googleEvent.organizer.email);
  }
  if (
    googleEvent?.creator?.email &&
    !emails.includes(googleEvent.creator.email)
  ) {
    guestList.push(googleEvent.creator);
  }

  //console.log('**', guestList);

  return guestList.filter((a) => {
    return a.email && !a.resource && !a.email.endsWith('calendar.google.com');
  });
}

export function sortObj(obj) {
  return Object.keys(obj)
    .sort()
    .reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export async function startUserSession(basicProfile, nextRoute) {

  if (basicProfile) {
    let offset = null;
    let timezone = null;
    try {
      offset = getTimeZoneOffset();
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch {
      offset = '+00:00';
      timezone = 'UTC';
    }

    const userProfile = {
      profile: {
        id: basicProfile.getId(),
        email: basicProfile.getEmail(),
        name: basicProfile.getName(),
        given_name: basicProfile.getGivenName(),
        family_name: basicProfile.getFamilyName(),
        image_url: basicProfile.getImageUrl(),
      },
      preferences: {
        offset: offset,
        timezone: timezone
      },
    };

    if (nextRoute?.query?.ref !== undefined) {
      userProfile.referral_code = nextRoute.query.ref
    }


    // Segment tracking
    window.meetric.identify(userProfile.profile.email, {
      name: userProfile.profile.name,
      email: userProfile.profile.email,
    });

    window.meetric.track('Session loaded - js');
    getCompanyUsers().then((r) => {
      store.commit('saveColleagues', r);
    });



    return setProfile(userProfile).then((response) => {
      store.commit('savePlan', response.plan);
      store.commit('savePreferences', response.preferences);
      store.commit('saveCalendars', response.calendars);
      store.commit('saveLogoutCount', response.logout_count);
      if (response.welcome_ids) {
        store.commit('saveWelcomeEventsId', response.welcome_ids);
      }
      if (response.welcome_id) {
        store.commit('saveMainWelcomeEventId', response.welcome_id);
      }

      if (response.notion_integration) {
        store.commit('setNotionIntegration', response.notion_integration);
      }
      if (response.slack_integration) {
        store.commit('setSlackIntegration', response.slack_integration);
      }
      if (response.offline_access) {
        store.commit('saveOfflineAccess', response.offline_access);
      }
      preferences.init(response.preferences);

      // const notFromCalendar =
      //   document.referrer != 'https://calendar.google.com/';

      if (
        (response.new_user && !isExtension() && !nextRoute?.query?.summary) ||
        (response.new_user && isExtension())
      ) {
        // go to onboarding unless there is summary in URL (meaning we wanna go to specific meeting)
        // in extension, go only if user came not from calendar
        router.replace({
          name: 'onboarding',
        });
      } else if (nextRoute?.query?.r) {
        router.replace({
          path: nextRoute.query.r,
          query: { cid: nextRoute.query.cid },
        });
      } else if (nextRoute?.name == 'signin') {
        router.replace({
          name: 'home',
        });
      } else if (router.currentRoute.query.r) {
        router.replace({
          name: router.currentRoute.query.r,
        });
      }
      return 'Profile set';
    });
  } else {
    // Anonymous login
    // openSocket();
    return 'Anonymous';
  }
}

export function getMeetingPassword(searchParams) {
  const urlParams = new URLSearchParams(searchParams);
  return urlParams.get('p');
}

export function waitUntilExists(cid, eid) {
  return getCalendarEventById(cid, eid).then(async (response) => {
    if (response) return response;

    // wait 1s and try again
    await waitPromise(1000);
    const second = await getCalendarEventById(cid, eid);
    if (second) return second;

    // wait 3s and try again
    await waitPromise(3000);
    const third = await getCalendarEventById(cid, eid);
    if (third) return third;

    // it just failed...
    router
      .replace({
        name: 'meetings',
      })
      .catch(() => { });
    return null;
  });
}

// redirect to meeting with check that meeting is actually available
// used when we create meetings in calendar for the user
export function redirWhenEventExists(cid, eid) {
  return getCalendarEventById(cid, eid).then(async (response) => {
    if (response) {
      router.replace({
        name: 'meeting',
        params: { id: eid, calendarId: cid },
      });
      return response;
    }

    // wait 1s and try again
    await waitPromise(1000);
    const second = await getCalendarEventById(cid, eid);
    if (second) {
      router.replace({
        name: 'meeting',
        params: { id: eid, calendarId: cid },
      });
      return second;
    }

    // wait 3s and try again
    await waitPromise(3000);
    const third = await getCalendarEventById(cid, eid);
    if (third) {
      router.replace({
        name: 'meeting',
        params: { id: eid, calendarId: cid },
      });
      return third;
    }

    // it just failed...
    router
      .replace({
        name: 'meetings',
      })
      .catch(() => { });
    return null;
  });
}

// promise for waiting specific time
const waitPromise = (duration, ...args) =>
  new Promise((resolve) => {
    setTimeout(resolve, duration, ...args);
  });

// get timezone offset
function getTimeZoneOffset() {
  const offset = new Date().getTimezoneOffset();
  const o = Math.abs(offset);
  return (
    (offset < 0 ? '+' : '-') +
    ('00' + Math.floor(o / 60)).slice(-2) +
    ':' +
    ('00' + (o % 60)).slice(-2)
  );
}

// real meeting is one that is loaded between its start and end time
// used for tracking purposes
export function liveMeeting() {
  const activeEvent = store.getters['getActiveEvent'];
  if (!activeEvent) return ''; // no active meeting
  if (activeEvent.sample) return 'sample';
  if (activeEvent.impromptu) return 'impromptu';

  const start = new Date(activeEvent.start);
  const end = new Date(activeEvent.end);
  const now = new Date();
  return start <= now && now <= end ? 'true' : 'false';
}

// is user in extension sidebar, used for tracking purposes
export function isExtension() {
  return store.getters['isExtension'];
}

export function hasScopeNeeded(scopeHave) {
  if (typeof scopeHave != 'string') return false;

  const respScopeArr = scopeHave.split(' ');
  const wantedScope = process.env.VUE_APP_GOOGLE_SCOPE.split(' ');
  return wantedScope.every((v) => respScopeArr.includes(v));
}

export { preferences };

// export function getUserDateFormat() {
//   let format = 'DD-MM-YYYY';
//   let formatGo = '02-01-2006';
//   try {
//     const locale = Intl.DateTimeFormat().resolvedOptions().locale;
//     if (locale.toLowerCase() == 'en-us') {
//       format = 'MM/DD/YYYY';
//       formatGo = '01/02/2006';
//     }
//   } catch {
//     format = 'DD-MM-YYYY';
//     formatGo = '02-01-2006';
//   }

//   return { js: format, go: formatGo };
// }
