import '@/assets/scripts/segment.js';
import { liveMeeting, isExtension } from '@/components/Utils';
import Vue from 'vue';

window.meetric = {
  track: function (name, props = {}) {
    window.analytics.track(name, {
      ...props,
      isAuth: Vue.gAuth.isAuthorized,
      livemeeting: liveMeeting(),
      isExtension: isExtension(),
    });
  },
  page: function (name, props = {}) {
    window.analytics.page(name, {
      ...props,
      isAuth: Vue.gAuth.isAuthorized,
      isExtension: isExtension(),
    });
  },
  identify: function (name, props = {}) {
    window.analytics.identify(name, {
      ...props,
      LOGGEDIN: 'swanson',
    });
  },
};
